<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-03-26 18:00:32
 * @LastEditTime : 2023-03-29 10:43:53
 * @LastEditors  : BigBigger
-->
<template>
	<div class="step2">
		<!-- 收货地址 -->
		<div class="table" v-if="courseInfo && courseInfo.hasShopAddress">
			<div class="t-head">
				<p>收货地址（我们会把对应的课程资料寄到您填写的收货地址）</p>
				<p class="cursor addAddress" @click="showAddress">
					+收货地址管理
				</p>
			</div>
			<div class="address-empty" v-if="!defaultAddress">
				还未选择收货地址
			</div>
			<div class="tbody-1" v-if="defaultAddress">
				<div class="address-item">
					<p class="label">收货人：</p>
					<p class="content">{{ defaultAddress.name }}</p>
				</div>
				<div class="address-item">
					<p class="label">联系方式：</p>
					<p class="content">{{ defaultAddress.phone }}</p>
				</div>
				<div class="address-item">
					<p class="label">收货地址：</p>
					<p class="content">
						{{ defaultAddress.provinceName }}
						{{ defaultAddress.cityName }}
						{{ defaultAddress.regionName }}
						{{ defaultAddress.detailAddress }}
					</p>
				</div>
			</div>
		</div>
		<!-- 岗位选择 -->
		<div
			class="table"
			v-if="jobInfo && courseInfo && courseInfo.interviewFlag === 1"
		>
			<div class="t-head">
				<p>岗位选择</p>
			</div>
			<div class="tbody-2">
				<div class="in-table">
					<div class="in-item">
						<div class="in-title"><span>部门名称</span></div>
						<div class="in-content">
							<span>{{ jobInfo.departmentName }}</span>
						</div>
					</div>
					<div class="in-item">
						<div class="in-title"><span>单位名称</span></div>
						<div class="in-content">
							<span>{{ jobInfo.employer }}</span>
						</div>
					</div>
					<div class="in-item">
						<div class="in-title"><span>职位名称</span></div>
						<div class="in-content">
							<span>{{ jobInfo.positionName }}</span>
						</div>
					</div>
					<div class="in-item" style="width: 10%">
						<div class="in-title"><span>招聘人数</span></div>
						<div class="in-content">
							<span>{{ jobInfo.numberCandidates }}</span>
						</div>
					</div>
					<div class="in-item" style="width: 25%">
						<div class="in-title"><span>职位代码</span></div>
						<div class="in-content">
							<span>{{ jobInfo.jobCode }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 课程信息 -->
		<div class="table" v-if="courseInfo">
			<div class="t-head">
				<p>课程信息</p>
			</div>
			<div class="tbody-2">
				<div class="in-table">
					<div class="in-item title-item">
						<div class="in-title"><span>课程名称</span></div>
						<div class="in-content">
							<span>{{ courseInfo.courseGroupName }}</span>
						</div>
					</div>
					<div
						class="in-item title-item classInfo"
						v-if="courseInfo.whetheHaveClassManager === 1"
					>
						<div class="in-title"><span>班级信息</span></div>
						<div class="in-content">
							<span
								v-for="(item, index) in flightNameList"
								:key="index"
								>{{ item }}</span
							>
						</div>
					</div>
					<!-- <div class="in-item type-item">
            <div class="in-title"><span>课程类型</span></div>
            <div class="in-content"><span>{{courseInfo.categoryName}}{{courseInfo.classWay}}</span></div>
          </div> -->
					<!-- <div class="in-item">
            <div class="in-title"><span>主讲</span></div>
            <div class="in-content2">
              <p v-for="(titem, tindex) of courseInfo.teachers" :key="tindex">
                {{titem.teacherName}} 
              </p>
            </div>
          </div> -->
					<div class="in-item">
						<div class="in-title"><span>备注</span></div>
						<div class="in-content">
							<span>{{ courseInfo.subtitle }}</span>
						</div>
					</div>
					<div class="in-item">
						<div class="in-title"><span>上课地址</span></div>
						<div class="in-content">
							<span>{{ courseInfo.classLocation }}</span>
						</div>
					</div>
					<div
						class="in-item"
						v-if="courseInfo.whetheHaveClassManager !== 1"
					>
						<div class="in-title"><span>时间</span></div>
						<div class="in-content">
							<span>{{ courseInfo.classTime }}</span>
						</div>
					</div>
					<div class="in-item" style="width: 10%">
						<div class="in-title"><span>价格</span></div>
						<div class="in-content">
							<span>￥{{ courseInfo.currentPrice }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-form
			:model="ruleForm"
			:rules="rules"
			ref="ruleForm"
			label-width="130px"
			class="infoForm"
		>
			<!-- 个人信息 -->
			<div
				class="table"
				v-if="courseInfo && courseInfo.whetherDisplayPersonalInfo === 1"
			>
				<div class="t-head">
					<p>
						个人信息（
						<span class="color-red">*</span>
						为必填项）
					</p>
				</div>
        <!-- 面试体验课 -->
        <div class="tbody-2" v-if="trialClass">
          <el-row>
            <el-col :span="7">
							<el-form-item label="真实姓名" prop="realName">
								<el-input
									v-model="ruleForm.realName"
									placeholder="请输入真实姓名"
								></el-input>
							</el-form-item>
						</el-col>
            <el-col :span="7">
							<el-form-item label="电话" prop="phone">
								<el-input
									v-model="ruleForm.phone"
									placeholder="请输入电话"
								></el-input>
							</el-form-item>
						</el-col>
            <el-col :span="10">
							<el-form-item label="退款支付宝账号" prop="alipayPhone">
								<el-input
									v-model="ruleForm.alipayPhone"
									placeholder="课程结束后三个工作日退回"
								></el-input>
							</el-form-item>
						</el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              
							<el-form-item label="考试类型" prop="testType">
                <el-select v-model="ruleForm.testType" placeholder="请选择考试类型">
                <el-option label="省考" value="省考"></el-option>
                <el-option label="国考" value="国考"></el-option>
                <el-option label="事业单位" value="事业单位"></el-option>
                <el-option label="国考、省考都已参加" value="国考、省考都已参加"></el-option>
              </el-select>
							</el-form-item>
						</el-col>
          </el-row>
        </div>
        <!-- 面试体验课 -->
				<div class="tbody-2" v-if="!trialClass">
					<el-row>
						<el-col :span="8">
							<el-form-item label="真实姓名" prop="realName">
								<el-input
									v-model="ruleForm.realName"
									placeholder="请输入真实姓名"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="性别" prop="gender">
								<el-radio-group v-model="ruleForm.gender">
									<el-radio :label="1">男</el-radio>
									<el-radio :label="2">女</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="电话" prop="phone">
								<el-input
									v-model="ruleForm.phone"
									placeholder="请输入电话"
								></el-input>
							</el-form-item>
						</el-col>
						<template
							v-if="courseInfo && courseInfo.interviewFlag === 1"
						>
							<el-col :span="8">
								<el-form-item label="身份证号" prop="idCard">
									<el-input
										v-model="ruleForm.idCard"
										placeholder="请输入身份证号"
									></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="学校" prop="school">
									<el-input
										v-model="ruleForm.school"
										placeholder="请输入学校名称"
									></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="学院" prop="profession">
									<el-input
										v-model="ruleForm.profession"
										placeholder="请输入所学学院"
									></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item
									label="笔试总分"
									prop="totalScore"
								>
									<el-input
										v-model="ruleForm.totalScore"
										placeholder="请输入笔试总分"
									></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="笔试排名" prop="ranking">
									<el-input
										v-model="ruleForm.ranking"
										placeholder="若未知，请填0"
									></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item
									label="准考证号"
									prop="admissionTicket"
								>
									<el-input
										v-model="ruleForm.admissionTicket"
										placeholder="请输入准考证号"
									></el-input>
								</el-form-item>
							</el-col>
							<!-- 江苏备注为查分密码 -->
							<!-- <template v-if="!isZJ">
                <el-col :span="8">
                  <el-form-item label="查分密码" prop="remark">
                    <el-input v-model="ruleForm.remark" placeholder="可不填"></el-input>
                  </el-form-item>
                </el-col>
              </template> -->
							<el-col :span="8">
								<el-form-item
									label="新/老学员"
									prop="newStudent"
								>
									<el-radio-group
										v-model="ruleForm.newStudent"
									>
										<el-radio :label="1">新学员</el-radio>
										<el-radio :label="2">老学员</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
              <!-- <el-col :span="16">
                <el-form-item label=" 成绩单截图凭证" prop="lowestScore">
                  <v-upload v-model="ruleForm.lowestScore" :maxSize="10485760" style="display: inline-block"></v-upload>
                </el-form-item>
              </el-col> -->
							<!-- <el-col :span="24" style="font-size: 12px; color: red;margin: 0 0 10px 30px;">
                  备注：出成绩前预报名同学，笔试分数和排名请默认0，出成绩后请在订单详情修改笔试分数和排名，出成绩后报名同学可正常填写自己真实笔试分数和排名。
                </el-col> -->
							<!-- <el-col v-if="isZJ" :span="16"> -->
							<!-- <el-form-item label-width="140px" label="本岗位最低进面分数" prop="lowestScore">
                    <el-input v-model="ruleForm.lowestScore" placeholder="若未知，请填0"></el-input>
                  </el-form-item> -->
							<!-- </el-col> -->
							<!-- <el-row>
                <el-col :span="14">
                  <el-form-item label="地址" prop="address">
                    <el-input v-model="ruleForm.address"></el-input>
                  </el-form-item>
                </el-col>
              </el-row> -->
						</template>
						<!-- 暂时取消备注区分 -->
						<!-- <template v-if="isZJ"> -->
						<el-col :span="8">
							<el-form-item label="应届/往届" prop="freshStudent">
								<el-radio-group v-model="ruleForm.freshStudent">
									<el-radio :label="1">应届</el-radio>
									<el-radio :label="2">往届</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col
							:span="8"
							v-if="courseInfo && courseInfo.interviewFlag === 3"
						>
							<el-form-item label="学校" prop="school">
								<el-input
									v-model="ruleForm.school"
									placeholder="请输入学校名称"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="16">
							<el-form-item label="备注">
								<el-input
									v-model="ruleForm.remark"
									@input="onInput()"
									placeholder="如有特殊要求，请备注"
								></el-input>
							</el-form-item>
						</el-col>
						<!-- </template> -->
					</el-row>
				</div>
			</div>
			<div
				class="table"
				v-if="
					courseInfo &&
					courseInfo.interviewFlag === 3 &&
					courseInfo.oldStudentsPreferential === 1
				"
			>
				<div class="t-head">
					<p>
						优惠信息<span
							>（老学员需提供以往学员证照片或课程支付成功订单详情截图）</span
						>
					</p>
				</div>
				<div class="tbody-2">
					<el-row>
						<el-col :span="8">
							<el-form-item
								label="是否老学员"
								prop="studentsPreferential"
							>
								<el-radio-group
									v-model="ruleForm.studentsPreferential"
								>
									<el-radio :label="1">是</el-radio>
									<el-radio :label="2">否</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col
							:span="8"
							v-if="ruleForm.studentsPreferential === 1"
						>
							<el-form-item label="老学员证明" prop="field01">
								<v-upload v-model="ruleForm.field01"></v-upload>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
			</div>
		</el-form>
    <!-- 面试课程不显示定金或全款选项，默认定金 -->
    <div class="noPay" v-if="jobInfo && courseInfo && courseInfo.interviewFlag === 1">
      <button class="confirm-btn" @click="showConfirm">
						提交{{
							courseInfo.interviewFlag === 1 ? '审核' : '订单'
						}}
					</button>
    </div>
    <!-- 笔试显示定金或全款选项 根据jobInfo岗位信息判断-->
		<!-- 支付形式 -->
		<div class="table" v-if="courseInfo && !jobInfo">
			<div class="t-head">
				<p>支付形式</p>
			</div>
			<div class="tbody-3">
				<div class="pay-top" v-if="payType">
					您选择的是：
					<el-select
						v-model="throughPay"
						@change="(val) => val === 1 && (payWay = 2)"
					>
						<el-option :value="1" :label="1 | throughPayFilter" />
						<el-option :value="0" :label="0 | throughPayFilter" />
					</el-select>
					<div class="color-red" style="margin-top: 10px">
						说明：{{
							throughPay === 1 ? courseInfo.msg1 : courseInfo.msg2
						}}
					</div>
				</div>
				<div class="pay">
					<div
						class="pay-item"
						@click="payWay = 2"
						:class="addClass"
						v-if="
							courseInfo.payWay === 2 || courseInfo.payWay === 3
						"
					>
						定金：￥{{ courseInfo.deposit }}
						<p class="tag"></p>
					</div>
					<div
						class="pay-item"
						@click="payWay = 1"
						:class="{ 'pay-item-active': payWay === 1 }"
						v-if="
							!(
								courseInfo.throughPay === 1 && throughPay === 1
							) &&
							(courseInfo.payWay === 1 || courseInfo.payWay === 2)
						"
					>
						全款：￥{{
							courseInfo.throughPay === 1 && throughPay === 1
								? courseInfo.showPrice
								: courseInfo.currentPrice
						}}
						<p class="tag"></p>
					</div>
					<div
						class="pay-tip"
						v-if="
							!(
								courseInfo.throughPay === 1 && throughPay === 1
							) &&
							courseInfo &&
							courseInfo.payWay !== 1 &&
							!['1575666087956000769'].includes(courseInfo.id)
						"
					>
						推荐定金（报名现场支付课程余款）
					</div>
				</div>
				<div class="pay-bottom">
					<!-- <span class="">{{courseInfo.costDescription}}</span> -->
					<span
						class="tip-imp"
						v-if="courseInfo && courseInfo.interviewFlag === 1"
						>请先提交审核，通过后缴费！</span
					>
					<span>实付金额：</span>
					<span class="color-red" v-if="payWay === 1"
						>￥{{ getPrice }}</span
					>
					<span class="color-red" v-if="payWay !== 1"
						>￥{{ courseInfo.deposit }}</span
					>
					<button class="confirm-btn" @click="showConfirm">
						提交{{
							courseInfo.interviewFlag === 1 ? '审核' : '订单'
						}}
					</button>
				</div>
			</div>
		</div>
		<!-- 编辑收货地址 -->
		<el-dialog
			:visible.sync="dialogVisible"
			:show-close="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			width="620px"
		>
			<div class="dialog-title" slot="title">
				管理收货地址
				<p class="addAddress" @click="addAddressHandle">
					<img
						class="add-icon"
						src="@/assets/images/icon/add.png"
						alt=""
					/>
					收货地址
				</p>
			</div>
			<div class="address-list">
				<!-- 新增收货地址 -->
				<AddressCreate
					@onCancel="showCreateAddress = false"
					@onSuccess="createAddress"
					v-if="showCreateAddress"
				/>
				<div v-for="(item, index) of addressList" :key="index">
					<div
						class="address-item"
						@click="addressClick(item)"
						:class="{
							'address-item-active':
								selectAddress && selectAddress.id === item.id,
						}"
						v-if="item.id !== showEditById"
					>
						<p class="address-tag"></p>
						<div class="ai-item">
							<div class="aii-item">
								<p class="ai-label">收货人：</p>
								<p class="ai-content">{{ item.name }}</p>
							</div>
							<div class="aii-item">
								<p class="ai-label">联系方式：</p>
								<p class="ai-content">{{ item.phone }}</p>
							</div>
							<div class="aii-item">
								<p class="ai-label">收货地址：</p>
								<p class="ai-content">
									<!-- {{item.provinceName}}
                  {{item.cityName}}
                  {{item.regionName}}
                  {{item.detailAddress}} -->
									{{ item.fullName }}
								</p>
							</div>
						</div>
						<div class="ai-btn-list" @click.stop="prevdefault">
							<p>
								<span class="ai-icon">
									<img
										src="@/assets/images/icon/edit.png"
										alt=""
									/>
								</span>
								<span
									class="ai-btn-text"
									@click="editAddress(item, index)"
									>修改</span
								>
							</p>
							<p>
								<span class="ai-icon">
									<img
										src="@/assets/images/icon/delete.png"
										alt=""
									/>
								</span>
								<el-popconfirm
									@confirm="deleteAddress(item.id)"
									title="确认删除？"
								>
									<span slot="reference" class="ai-btn-text"
										>删除</span
									>
								</el-popconfirm>
							</p>
							<!--  @click.stop="selectAdd(item)" -->
							<p>
								<span class="ai-icon">
									<!-- <img src="@/assets/images/icon/delete.png" alt=""> -->
								</span>
								<span
									slot="reference"
									@click.stop="addressClick(item)"
									class="ai-btn-text font-red"
									>选择</span
								>
							</p>
						</div>
					</div>
					<AddressCreate
						:detail="item"
						v-if="showEditById === item.id"
						@onCancel="showEditById = null"
						@onSuccess="createAddress"
					/>
				</div>
				<img
					v-if="addressList.length === 0 && !showCreateAddress"
					class="empty-address"
					src="@/assets/images/order/address.png"
					alt=""
				/>

				<div class="dialog-btn">
					<!-- <el-button style="width:137px;" type="primary" @click="setDefaultAddress(selectAddress)">确定</el-button> -->
					<el-button
						style="width: 137px"
						@click="setDefaultAddress(selectAddress)"
						>关闭</el-button
					>
				</div>
			</div>
		</el-dialog>
		<!-- 删除收货地址提示 -->
		<el-dialog
			:visible.sync="deleteVisible"
			:show-close="false"
			:close-on-click-modal="false"
			width="450px"
		>
			<div class="delete-title">提示</div>
			<div class="delete-title">是否删除收货地址</div>
			<div class="delete-button-box">
				<el-button style="width: 137px" type="primary">删除</el-button>
				<el-button style="width: 137px" @click="deleteVisible = false"
					>取消</el-button
				>
			</div>
		</el-dialog>
		<!-- 确认信息 -->
		<el-dialog :visible.sync="detailVisible" top="5vh" width="810px">
			<div class="dialog-scroll">
				<p slot="title"></p>
				<template v-if="courseInfo">
					<p class="dialog-title">课程信息</p>
					<div class="dialog-body">
						<el-row>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>课程名称</span>
									</div>
									<div class="dialog-content">
										<span>{{
											courseInfo.courseGroupName
										}}</span>
									</div>
								</div>
							</el-col>
							<el-col
								:span="24"
								v-if="courseInfo.whetheHaveClassManager === 1"
							>
								<div class="dialog-item">
									<div class="dialog-label">
										<span>班级信息</span>
									</div>
									<div class="dialog-content class-item">
										<span
											v-for="(
												item, index
											) in flightNameList"
											:key="index"
											>{{ item }}</span
										>
									</div>
								</div>
							</el-col>
							<!-- <el-col :span="24">
                <div class="dialog-item">
                  <div class="dialog-label">
                    <span>上课形式</span>
                  </div>
                  <div class="dialog-content">
                    <span>{{courseInfo.categoryName}}{{courseInfo.classWay}}</span>
                  </div>
                </div>
              </el-col> -->
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>上课地址</span>
									</div>
									<div class="dialog-content">
										<span>{{
											courseInfo.classLocation
										}}</span>
									</div>
								</div>
							</el-col>
							<el-col
								:span="24"
								v-if="courseInfo.whetheHaveClassManager !== 1"
							>
								<div class="dialog-item">
									<div class="dialog-label">
										<span>时间</span>
									</div>
									<div class="dialog-content">
										<span>{{ courseInfo.classTime }}</span>
									</div>
								</div>
							</el-col>
							<!-- <el-col :span="24">
                <div class="dialog-item">
                  <div class="dialog-label">
                    <span>主讲</span>
                  </div>
                  <div class="dialog-content">
                    <span v-for="(titem, tindex) of courseInfo.teachers" :key="tindex">{{titem.teacherName}} </span>
                  </div>
                </div>
              </el-col> -->
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>备注</span>
									</div>
									<div class="dialog-content">
										<span>{{ courseInfo.subtitle }} </span>
									</div>
								</div>
							</el-col>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>价格</span>
									</div>
									<div class="dialog-content">
										<span class="text-red"
											>￥{{
												courseInfo.throughPay === 1 &&
												throughPay === 1
													? courseInfo.showPrice
													: courseInfo.currentPrice
											}}</span
										>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</template>
				<template v-if="defaultAddress && courseInfo.hasShopAddress">
					<p class="dialog-title">收货地址</p>
					<div class="dialog-body">
						<el-row>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>收货人</span>
									</div>
									<div class="dialog-content">
										<span>{{ defaultAddress.name }}</span>
									</div>
								</div>
							</el-col>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>联系方式</span>
									</div>
									<div class="dialog-content">
										<span>{{ defaultAddress.phone }}</span>
									</div>
								</div>
							</el-col>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>收货地址</span>
									</div>
									<div class="dialog-content">
										<span>
											{{ defaultAddress.provinceName }}
											{{ defaultAddress.cityName }}
											{{ defaultAddress.regionName }}
											{{ defaultAddress.detailAddress }}
										</span>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</template>
				<template
					v-if="
						jobInfo && courseInfo && courseInfo.interviewFlag === 1
					"
				>
					<p class="dialog-title">岗位选择</p>
					<div class="dialog-body">
						<el-row>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>单位名称</span>
									</div>
									<div class="dialog-content">
										<span>{{ jobInfo.employer }}</span>
									</div>
								</div>
							</el-col>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>部门名称</span>
									</div>
									<div class="dialog-content">
										<span>{{
											jobInfo.departmentName
										}}</span>
									</div>
								</div>
							</el-col>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>职位名称</span>
									</div>
									<div class="dialog-content">
										<span>{{ jobInfo.positionName }}</span>
									</div>
								</div>
							</el-col>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>职位代码</span>
									</div>
									<div class="dialog-content">
										<span>{{ jobInfo.jobCode }}</span>
									</div>
								</div>
							</el-col>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>招聘人数</span>
									</div>
									<div class="dialog-content">
										<span>{{
											jobInfo.numberCandidates
										}}</span>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</template>
				<template
          v-if="
						courseInfo &&
						courseInfo.whetherDisplayPersonalInfo === 1
					"
				>
					<p class="dialog-title" v-if="!trialClass">个人信息</p>
					<div class="dialog-body" v-if="!trialClass">
						<el-row>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>真实姓名</span>
									</div>
									<div class="dialog-content">
										<span>{{ ruleForm.realName }}</span>
									</div>
								</div>
							</el-col>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>性别</span>
									</div>
									<div class="dialog-content">
										<span>{{
											ruleForm.gender | sexFilter
										}}</span>
									</div>
								</div>
							</el-col>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>电话</span>
									</div>
									<div class="dialog-content">
										<span>{{ ruleForm.phone }}</span>
									</div>
								</div>
							</el-col>
							<template
								v-if="
									courseInfo && courseInfo.interviewFlag === 1
								"
							>
								<el-col :span="24">
									<div class="dialog-item">
										<div class="dialog-label">
											<span>新/老学员</span>
										</div>
										<div class="dialog-content">
											<span>{{
												ruleForm.newStudent
													| studentFilter
											}}</span>
										</div>
									</div>
								</el-col>
								<el-col :span="24">
									<div class="dialog-item">
										<div class="dialog-label">
											<span>笔试总分</span>
										</div>
										<div class="dialog-content">
											<span>{{
												ruleForm.totalScore
											}}</span>
										</div>
									</div>
								</el-col>
								<el-col :span="24">
									<div class="dialog-item">
										<div class="dialog-label">
											<span>笔试排名</span>
										</div>
										<div class="dialog-content">
											<span>{{ ruleForm.ranking }}</span>
										</div>
									</div>
								</el-col>
								<!-- <el-col :span="24">
                  <div class="dialog-item">
                    <div class="dialog-label">
                      <span>成绩单截图凭证</span>
                    </div>
                    <div class="dialog-content">
                      <img :src="ruleForm.lowestScore" style="width: 50px;height: 50px;" alt="">
                    </div>
                  </div>
                </el-col> -->
								<!-- <el-col :span="24">
                  <div class="dialog-item">
                    <div class="dialog-label">
                      <span>本岗位最低进面分数</span>
                    </div>
                    <div class="dialog-content">
                      <span>{{ruleForm.lowestScore}}</span>
                    </div>
                  </div>
                </el-col> -->
								<el-col :span="24">
									<div class="dialog-item">
										<div class="dialog-label">
											<span>身份证号</span>
										</div>
										<div class="dialog-content">
											<span>{{ ruleForm.idCard }}</span>
										</div>
									</div>
								</el-col>
								<el-col :span="24">
									<div class="dialog-item">
										<div class="dialog-label">
											<span>准考证号</span>
										</div>
										<div class="dialog-content">
											<span>{{
												ruleForm.admissionTicket
											}}</span>
										</div>
									</div>
								</el-col>
								<el-col :span="24">
									<div class="dialog-item">
										<div class="dialog-label">
											<span>学院</span>
										</div>
										<div class="dialog-content">
											<span>{{
												ruleForm.profession
											}}</span>
										</div>
									</div>
								</el-col>

								<el-col :span="24">
									<div class="dialog-item">
										<div class="dialog-label">
											<span>地址</span>
										</div>
										<div class="dialog-content">
											<span>{{ ruleForm.address }}</span>
										</div>
									</div>
								</el-col>
							</template>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>应届/往届</span>
									</div>
									<div class="dialog-content">
										<span>{{
											ruleForm.freshStudent
												| freshStudentFilter
										}}</span>
									</div>
								</div>
							</el-col>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>学校</span>
									</div>
									<div class="dialog-content">
										<span>{{ ruleForm.school }}</span>
									</div>
								</div>
							</el-col>
							<!-- <el-col :span="24" v-if="isZJ"> -->
							<div class="dialog-item">
								<div class="dialog-label">
									<span>备注</span>
								</div>
								<div class="dialog-content">
									<span>{{ ruleForm.remark }}</span>
								</div>
							</div>
							<!-- </el-col> -->
							<!-- <el-col :span="24" v-else>
                <div class="dialog-item">
                  <div class="dialog-label">
                    <span>查分密码</span>
                  </div>
                  <div class="dialog-content">
                    <span>{{ruleForm.remark}}</span>
                  </div>
                </div>
              </el-col> -->
						</el-row>
					</div>
				</template>
				<template
					v-if="
						courseInfo &&
						courseInfo.interviewFlag === 3 &&
						courseInfo.oldStudentsPreferential === 1
					"
				>
					<p class="dialog-title">优惠信息</p>
					<div class="dialog-body">
						<el-row>
							<el-col :span="24">
								<div class="dialog-item">
									<div class="dialog-label">
										<span>是否老学员</span>
									</div>
									<div class="dialog-content">
										{{
											ruleForm.studentsPreferential === 1
												? '是'
												: '否'
										}}
									</div>
								</div>
							</el-col>
							<el-col
								:span="24"
								v-if="ruleForm.studentsPreferential === 1"
							>
								<div class="dialog-item">
									<div class="dialog-label">
										<span>老学员证明</span>
									</div>
									<div class="dialog-content">
										<img
											:src="ruleForm.field01"
											style="width: 50px; height: 50px"
											alt=""
										/>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</template>
        <!-- 没有岗位信息就是面试课，隐藏支付形式 -->
				<p class="dialog-title" v-if="!jobInfo">支付形式</p>
				<div class="dialog-body" v-if="!jobInfo">
					<el-row>
						<el-col :span="24" v-if="payType">
							<div class="dialog-item">
								<div class="dialog-label">
									<span>缴费方式</span>
								</div>
								<div class="dialog-content">
									<span>{{ payType }}</span>
								</div>
							</div>
						</el-col>
						<el-col :span="24">
							<div class="dialog-item">
								<div class="dialog-label">
									<span>支付方式</span>
								</div>
								<div class="dialog-content">
									<span>{{ payWay | paywayFilter }}</span>
									<span class="font-color" v-if="courseInfo"
										>￥{{
											+payWay === 1
												? getPrice
												: courseInfo.deposit
										}}</span
									>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<!-- submitOrder -->
			<div slot="footer" class="confirm-dialog-footer">
				<div>
					<div class="fin-tip">
						请确认所填写信息是否有误，若无误请点击确认
					</div>
					<div
						class="fin-tip"
						v-if="
							isZJ && courseInfo && courseInfo.interviewFlag !== 3
						"
					>
						注：定金支付成功后，职位1：1被保护，放弃培训者不退费
					</div>
				</div>
				<el-button
					style="width: 137px"
					type="primary"
					@click="submitOrder"
					>确认</el-button
				>
				<el-button style="width: 137px" @click="detailVisible = false"
					>取消</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	PAYWAY,
	SEX,
	STUDENT,
	FRESHSTUDENT,
	THROUGH_PAY,
} from '@/config/config';
import AddressCreate from './AddressCreate.vue';
import { subtract, numeric } from 'mathjs';
import {
	getAddressList,
	deleteAddress,
	setDefaultAddress,
	getDefaultAddressList,
	createOrder,
	getPersonalInfo,
	getFlightName,
	getUserInfo,
} from '@/api/api';
export default {
	name: 'Step2',
	data() {
		return {
      // 是否为面试体验课
      trialClass:true,
			showCreateAddress: false, // 是否显示新增地址
			dialogVisible: false,
			deleteVisible: false,
			detailVisible: false,
			isZJ: process.env.VUE_APP_AREA === 'zj' ? true : false,
			payWay: 2,
			throughPay: 0,
			ruleForm: {
				realName: null,
				gender: 1,
				phone: null,
				totalScore: null,
				ranking: null,
				newStudent: 1,
				idCard: null,
				admissionTicket: null,
				freshStudent: 1,
				school: '学校名称',
				profession: null,
				address: null,
				remark: null,
				flightIds: [],
        // 退款支付宝账号
        alipayPhone: null,
        // 考试类型
        testType: '省考',
			},
			flightNameList: [],
			options: [],
			addressList: [],
			showEditById: null,
			defaultAddress: null,
			jobInfo: null,
			courseInfo: null,
			selectAddress: {},
		};
	},
	components: {
		AddressCreate,
	},
	mounted() {
		this.getAddressList();
		this.getDefaultAddressList();
		this.getJobInfo();
		this.getCourseInfo();
		this.getPersonalInfo();
    if(this.courseInfo.courseGroupName.indexOf('体验课') !== -1) {
      this.trialClass = true
    }else{
      this.trialClass = false
    }
	},
	computed: {
		addClass() {
			return {
				'pay-item-active': this.payWay === 2 || this.payWay === 3,
			};
		},
		payType() {
			if (this.courseInfo.throughPay === 1) {
				return THROUGH_PAY[this.throughPay] || '';
			}
			return;
		},
		getPrice() {
			if (!this.courseInfo) return '';
			if (
				this.courseInfo.interviewFlag === 3 &&
				this.courseInfo.payWay === 1 &&
				this.courseInfo.oldStudentsPreferential === 1 &&
				this.ruleForm.studentsPreferential === 1 &&
				this.ruleForm.field01
			) {
				return (
					this.courseInfo.afterOldStudentsPrice +
					` (老学员优惠 ${this.courseInfo.oldStudentsPrice})`
				);
				// return subtract(numeric(this.courseInfo.currentPrice, 'BigNumber'), numeric(this.courseInfo.oldStudentsPrice, 'BigNumber')) + ` (老学员优惠 ${this.courseInfo.oldStudentsPrice})`;
			}
			if (this.courseInfo.throughPay === 1 && this.throughPay === 1)
				return this.courseInfo.showPrice;
			return this.courseInfo.currentPrice;
		},
		rules() {
			const schoolReq = this.isZJ;
			return {
				realName: [
					{
						required: true,
						message: '请输入真实姓名',
						trigger: 'blur',
					},
					// { max: 5, message: '最多填写 5 个字符', trigger: 'blur' },
				],
				gender: [{ required: true, message: '请选择性别' }],
				studentsPreferential: [
					{ required: true, message: '请选择是否老学员' },
				],
				// lowestScore: [{ required: true, message: '请上传证明' }],
				field01: [{ required: true, message: '请上传老学员证明' }],
				phone: [
					{ required: true, message: '请输入电话', trigger: 'blur' },
					{
						pattern:
							/^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/,
						message: '请填写正确的手机号码',
						trigger: 'blur',
					},
				],
				totalScore: [
					{ required: true, message: '请输入笔试总分' },
					{
						pattern:
							/(^[1-9]([0-9]+)?(\.[0-9]{1,3})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
						message: '请填写正确的总分',
						trigger: 'blur',
					},
				],
				ranking: [
					{ required: true, message: '请输入笔试排名' },
					{
						pattern: /^[0-9][0-9]?$/,
						message: '请输入正确排名',
						trigger: 'blur',
					},
				],
				// newStudent: [{required: true, message: '请选择新/老学员',}],
				idCard: [
					{
						required: true,
						message: '请输入身份证号',
						trigger: 'blur',
					},
					{
						pattern:
							/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
						message: '请填写正确的身份证号',
						trigger: 'blur',
					},
				],
				admissionTicket: [
					{
						required: true,
						message: '请输入准考证号',
						trigger: 'blur',
					},
				],
				// remark: [
				//   {required: true, message: '请输入查分密码', trigger: 'blur' },
				// ],
				freshStudent: [{ required: true, message: '请选择应届/往届' }],
				school: [
					{ max: 32, message: '最多填写 32 个字符', trigger: 'blur' },
					{
						message: '请输入学校',
						trigger: 'blur',
						required: schoolReq,
					},
				],
        alipayPhone:[
          {
            required: true,
            message: '请输入退款支付宝账号',
						trigger: 'blur',
          }
        ],
				profession: [
          {
            required: true,
            message: '请输入学院',
						trigger: 'blur',
          },
					{
						max: 128,
						message: '最多填写 128 个字符',
						trigger: 'blur',
					},
				],
				address: [
					{
						max: 128,
						message: '最多填写 128 个字符',
						trigger: 'blur',
					},
				],
			};
		},
	},
	filters: {
		paywayFilter(val) {
			return PAYWAY[+val];
		},
		throughPayFilter(val) {
			return THROUGH_PAY[+val];
		},
		sexFilter(val) {
			return SEX[+val];
		},
		studentFilter(val) {
			return STUDENT[+val];
		},
		freshStudentFilter(val) {
			return FRESHSTUDENT[+val];
		},
	},
	methods: {
		onInput() {
			this.$forceUpdate();
		},
		addressClick(item) {
			this.selectAddress = item;
			this.setDefaultAddress(this.selectAddress);
		},
		selectAdd(item) {
			this.selectAddress = item;
			// this.setDefaultAddress(item)
		},
		isvalidPhone(str) {
			const reg = /^1[3456789]\d{9}$/;
			return reg.test(str);
		},
		showConfirm() {
      if(this.trialClass) {
        this.ruleForm.remark = this.ruleForm.testType + "+" + this.ruleForm.alipayPhone
      }
			if (this.courseInfo.whetherDisplayPersonalInfo === 1) {
				this.$refs.ruleForm.validate((valid) => {
					if (!valid) {
						return false;
					}
					if (
						this.courseInfo.hasShopAddress === 1 &&
						!this.defaultAddress
					) {
						this.$message.error('请先选择收货地址');
						return false;
					}
					this.detailVisible = true;
				});
			} else {
				if (
					this.courseInfo.hasShopAddress === 1 &&
					!this.defaultAddress
				) {
					this.$message.error('请先选择收货地址');
					return false;
				}
				this.detailVisible = true;
			}
		},
		submitOrder() {
			if (this.courseInfo.whetherDisplayPersonalInfo === 1) {
				this.$refs.ruleForm.validate((valid) => {
					if (!valid) {
						return false;
					}
					let params = {
						type: this.courseInfo.interviewFlag,
						payWay: this.payWay,
						currentPrice: this.courseInfo.currentPrice,
						courseGroupId: this.courseInfo.id,
						courseGroupName: this.courseInfo.courseGroupName,
						jobTableId: this.jobInfo ? this.jobInfo.id : '',
						flightIds: this.flightIds,
						personalInfo: this.ruleForm,
					};
					if (
						this.courseInfo &&
						this.courseInfo.interviewFlag === 1
					) {
						params.personalInfo = this.ruleForm;
					} else {
						params.personalInfo = {
							realName: this.ruleForm.realName,
							gender: this.ruleForm.gender,
							phone: this.ruleForm.phone,
							remark: this.ruleForm.remark,
							freshStudent: this.ruleForm.freshStudent,
						};
						if (
							this.courseInfo &&
							this.courseInfo.interviewFlag === 3
						) {
							if (this.courseInfo.oldStudentsPreferential === 1) {
								params.personalInfo.studentsPreferential =
									this.ruleForm.studentsPreferential;
								params.personalInfo.field01 =
									this.ruleForm.field01;
							}
							params.personalInfo.school = this.ruleForm.school;
						}
					}
					if (this.courseInfo?.throughPay === 1)
						params.throughPay = this.throughPay;
					// 如果需要收货地址
					if (
						this.courseInfo.hasShopAddress === 1 &&
						this.defaultAddress
					) {
						params.userAddressManagementId = this.defaultAddress.id;
					}
					this.createOrder(params);
				});
			} else {
				let params = {
					type: this.courseInfo.interviewFlag,
					payWay: this.payWay,
					currentPrice: this.courseInfo.currentPrice,
					courseGroupId: this.courseInfo.id,
					courseGroupName: this.courseInfo.courseGroupName,
					jobTableId: this.jobInfo ? this.jobInfo.id : '',
					personalInfo: {
						realName: '',
						gender: '',
						phone: '',
						remark: '',
					},
				};

				// 如果需要收货地址
				if (
					this.courseInfo.hasShopAddress === 1 &&
					this.defaultAddress
				) {
					params.userAddressManagementId = this.defaultAddress.id;
				}

				this.createOrder(params);
			}
		},
		async createOrder(params) {
      if(this.$route.query.ysn) {
        params.sourceNum = this.$route.query.ysn
      }
			let response = await createOrder(params);
			if (+response.returnCode !== 10001) {
				this.$message.error(response.returnMsg);
				return false;
			}
			// 清理本地缓存
			this.$store.dispatch('clearOrderInfo');
			this.$message.success(response.returnMsg);
			this.detailVisible = false;
			// 区分零元课程
			if (this.courseInfo.currentPrice === 0) {
				this.$router.replace({
					path: `/course/signup?step=4&orderId=${response.data.id}`,
				});
			} else {
				this.$router.replace({
					path: `/course/signup?step=3&orderId=${response.data.id}`,
				});
			}
		},
		getCourseInfo() {
			let orderInfo = this.$store.state.orderInfo || null;
			if (orderInfo) {
				this.courseInfo = orderInfo.loCourseInfo;
				this.flightIds = orderInfo.classInfo;
				if (orderInfo.loCourseInfo.whetheHaveClassManager === 1) {
					this.getFlightName();
				}
				if (this.courseInfo.payWay === 1) {
					this.payWay = 1;
				} else {
					this.payWay = 2;
				}
				this.throughPay = orderInfo.throughPay;
				if (orderInfo.throughPay === 1) this.payWay = 2;
			}
		},
		async getFlightName() {
			let params = {
				flightIds: this.flightIds,
			};
			let response = await getFlightName(params);
			if (+response.returnCode !== 10001) {
				this.$toast(response.returnMsg);
				return false;
			}
			let str = response.data;
			let strs = str.split(',');
			this.flightNameList = strs;
		},

		getJobInfo() {
			let orderInfo = this.$store.state.orderInfo;
			if (orderInfo) {
				this.jobInfo = orderInfo.loJboInfo;
			}
		},
		async getDefaultAddressList() {
			let response = await getDefaultAddressList({});
			if (+response.returnCode !== 10001) {
				this.$message.error(returnMsg);
				return false;
			}
			this.defaultAddress = response.data || null;
			///
			this.selectAddress = response.data;
		},
		async setDefaultAddress(item) {
			if (!item) {
				this.dialogVisible = false;
				return false;
			}
			let params = {
				id: item.id,
			};
			let response = await setDefaultAddress(params);
			if (+response.returnCode !== 10001) {
				this.$message.error(response.returnMsg);
				return false;
			}
			// this.$message.success(response.returnMsg)
			this.getAddressList();
			this.getDefaultAddressList();
			this.dialogVisible = false;
		},
		addAddressHandle() {
			// this.editAddress = null
			this.showCreateAddress = true;
		},
		editAddress(item) {
			this.showEditById = item.id;
		},
		async deleteAddress(id) {
			let params = {
				id: id,
			};
			let response = await deleteAddress(params);
			if (+response.returnCode !== 10001) {
				this.$message.error(response.returnMsg);
				return false;
			}
			this.$message.success(response.returnMsg);
			this.getAddressList();
		},
		async getAddressList() {
			let response = await getAddressList({});
			if (+response.returnCode !== 10001) {
				this.$message.error(response.returnMsg);
				return false;
			}
			this.addressList = response.data;
		},
		createAddress() {
			this.showCreateAddress = false;
			this.showEditById = null;
			// 获取列表
			this.getAddressList();
		},
		showAddress() {
			this.dialogVisible = true;
		},
		prevdefault() {},

		//获取用户个人信息
		async getPersonalInfo() {
			let response = await getPersonalInfo({});
			if (+response.returnCode !== 10001) {
				this.$message.error(response.returnMsg);
				return false;
			}
			if (response.data) {
				response.data.totalScore = '';
				response.data.ranking = '';
				this.setDefaultInfo(response.data);
			} else {
				getUserInfo().then((res) => {
					this.ruleForm.phone = res.data.phone;
				});
			}
		},
		setDefaultInfo(defaultInfo) {
			//console.log(defaultInfo)
			this.ruleForm.realName = defaultInfo.realName;
			this.ruleForm.gender = defaultInfo.gender || 1;
			this.ruleForm.phone = defaultInfo.phone;
			this.ruleForm.remark = defaultInfo.remark;
			if (this.courseInfo.interviewFlag == 1) {
				this.ruleForm.idCard = defaultInfo.idCard;
				this.ruleForm.address = defaultInfo.address;
				this.ruleForm.totalScore = defaultInfo.totalScore;
				this.ruleForm.ranking = defaultInfo.ranking;
				this.ruleForm.newStudent = defaultInfo.newStudent || 1;
				// this.ruleForm.admissionTicket = ''
				// this.ruleForm.admissionTicket = defaultInfo.admissionTicket
				this.ruleForm.school = defaultInfo.school;
				this.ruleForm.profession = defaultInfo.profession;
				this.ruleForm.freshStudent = defaultInfo.freshStudent || 1;
				// this.ruleForm.freshStudent = 1
			}
			// 取消设置默认值时的验证
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
	},
};
</script>

<style lang="less" scoped>
.step2 {
	width: 1130px;
	background: #ffffff;
	border: 1px solid #e8e8e8;
	border-radius: 4px;
	overflow: hidden;
	.table {
		font-size: 14px;
		.t-head {
			height: 38px;
			background: #f2f2f2;
			// border-radius: 4px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 20px;
			font-size: 14px;
			font-weight: bold;
			color: #333333;
		}
		.label {
			color: #5b6b73;
		}
		.content {
			color: #333333;
		}
		.tbody-1 {
			padding: 20px 50px;
			.address-item {
				display: flex;
				line-height: 16px;
				margin: 10px 0;
				.label {
					text-align: right;
					width: 70px;
				}
			}
		}
		.tbody-2 {
			padding: 29px 50px 32px;
			justify-content: center;
			::v-deep .el-row {
				width: 100%;
			}
		}
		.tbody-3 {
			padding: 29px 80px 32px;
		}
	}
	.in-table {
		width: 1030px;
		border: 1px solid #dbdbdb;
		display: flex;
		.in-item {
			flex-grow: 1;
			flex-shrink: 1;
			width: 20%;
			display: flex;
			flex-direction: column;
			.in-title {
				height: 42px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #f8f8f8;
				border-bottom: 1px solid #dbdbdb;
				font-weight: bold;
				flex-grow: 0;
				flex-shrink: 0;
			}

			.in-content {
				min-height: 58px;
				display: flex;
				justify-content: center;
				align-items: center;
				// padding: 0 10px;
				padding: 5px 10px;
				height: 100%;
				span {
					word-break: break-all;
					display: inline-block;
					text-align: center;
					// width: 126px;
				}
			}
			.in-content2 {
				min-height: 66px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 10px 0;
				p {
					word-break: break-all;
					text-align: center;
					// width: 126px;
					text-align: center;
					color: #2e8df4;
					line-height: 25px;
				}
			}
		}
		.title-item {
			// width:250px;
			.in-content {
				// span{
				//   width:210px;
				//   text-align: left;
				// }
			}
		}
		.classInfo {
			.in-content {
				display: block;
				span {
					text-align: center;
					line-height: 20px;
				}
			}
		}

		.type-item {
			width: 176px;
			.in-content {
				span {
					width: 200px;
				}
			}
		}
	}
	.pay-top {
		margin-bottom: 20px;
	}
	.pay {
		display: flex;
		width: 100%;
		align-items: center;
		margin-bottom: 40px;
		.pay-item {
			width: 186px;
			height: 56px;
			line-height: 56px;
			background: #ffffff;
			border: 1px solid #d8d8d8;
			border-radius: 4px;
			text-align: center;
			cursor: pointer;
			margin-right: 50px;
			position: relative;
			overflow: hidden;
			font-size: 16px;
			color: #333;
			.tag {
				position: absolute;
				top: 0;
				right: 0;
				width: 28px;
				height: 28px;
				// background: #FF3F47;
				background: url('../../../assets/images/icon/select.png')
					no-repeat;
				background-size: contain;
				opacity: 0;
			}
		}
		.pay-item-active {
			border: 1px solid #ff3f47;
			opacity: 1;
			.tag {
				opacity: 1 !important;
			}
		}
	}
	.pay-bottom {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		color: #333;
		font-size: 16px;
	}
  .confirm-btn {
    width: 148px;
    height: 40px;
    background: #ff3f47;
    border-radius: 6px;
    color: #fff;
    font-size: 18px;
    // font-weight: bold;
    margin-left: 20px;
  }
  .noPay{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
  }
	.dialog-title {
		font-size: 18px;
		font-weight: bold;
		color: #333333;
		text-align: center;
		position: relative;
		.addAddress {
			color: #108ee9;
			font-size: 12px;
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
		}
	}
	.address-list {
		width: 540px;
		margin: 0 auto;
		.address-item {
			// width: 100%;
			min-height: 117px;
			padding: 20px;
			border: 1px solid #e8e8e8;
			border-radius: 6px;
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
			font-size: 14px;
			position: relative;
			overflow: hidden;
			.address-tag {
				position: absolute;
				top: 0;
				right: 0;
				width: 29px;
				height: 29px;
				background: url('../../../assets/images/icon/select.png')
					no-repeat;
				background-size: contain;
				opacity: 0;
			}
			.ai-item {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex-grow: 1;
				flex-shrink: 1;
				width: 100%;
			}
			.aii-item {
				display: flex;
				width: 100%;
			}
			.ai-label {
				display: inline-block;
				color: #8a9095;
				// max-width: 100px;
				width: 80px;
				word-break: break-all;
				// width: 60px;
				text-align: right;
				flex-grow: 0;
				flex-shrink: 0;
				line-height: 20px;
			}

			.ai-content {
				color: #333333;
				word-break: break-all;
				width: 100%;
				line-height: 20px;
			}
		}
		.ai-btn-list {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin: 10px 0;
			flex-grow: 0;
			flex-shrink: 0;
			p {
				display: flex;
				align-items: center;
				margin: 5px 0;
			}
			.ai-icon {
				display: block;
				width: 12px;
				height: 12px;
				// background: #f00;
				margin-right: 6px;
				img {
					height: 12px;
					vertical-align: top;
				}
			}
			.ai-btn-text {
				color: #108ee9;
				font-size: 12px;
			}
		}
	}
	.dialog-btn {
		display: flex;
		justify-content: center;
	}
	.edit-add {
		margin-bottom: 30px;
	}
	.add-icon {
		width: 12px;
	}
}
.delete-title {
	font-size: 16px;
	font-weight: bold;
	color: #333333;
	line-height: 14px;
	text-align: center;
	margin-bottom: 38px;
}
.delete-tip {
	font-size: 16px;
	font-weight: 400;
	color: #333333;
	line-height: 14px;
	margin-bottom: 42px;
}
.delete-button-box {
	display: flex;
	justify-content: center;
	align-items: center;
}
.empty-address {
	width: 137px;
	display: block;
	margin: 100px auto;
}
.address-item-active {
	border: 1px solid #fb4a3e !important;
	.address-tag {
		opacity: 1 !important;
	}
}
.dialog-title {
	font-size: 18px;
	font-weight: bold;
	color: #333333;
	text-align: center;
	margin: 16px 0;
}
.dialog-body {
	border-top: 1px solid #dbdbdb;
	border-left: 1px solid #dbdbdb;
	.dialog-item {
		display: flex;
		font-size: 14px;
		font-weight: 400;
		color: #333333;
		line-height: 24px;
		min-height: 40px;
		border-right: 1px solid #dbdbdb;
		border-bottom: 1px solid #dbdbdb;
		.dialog-label {
			width: 95px;
			background: #f2f2f2;
			flex-grow: 0;
			flex-shrink: 0;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.dialog-content {
			flex-grow: 1;
			flex-shrink: 1;
			padding-left: 20px;
			display: flex;
			align-items: center;
			span {
				margin-right: 15px;
			}
		}
		.class-item {
			display: block;
		}
	}
}
.confirm-dialog-footer {
	// display: flex;
	// justify-content: center;
	text-align: center;
}
.text-red {
	color: #fb4a3e !important;
}
.tip-imp {
	margin-right: 20px;
	font-size: 18px;
	font-weight: bold;
	color: #fb4a3e;
}
.dialog-scroll {
	height: 100%;
	overflow-y: auto;
}
.fin-tip {
	color: #fb4a3e;
	text-align: center;
	font-size: 16px;
	margin-bottom: 15px;
	font-weight: bold;
}
.address-empty {
	text-align: center;
	padding: 20px 0;
	color: #fb4a3e;
}
.addAddress {
	color: #2e8df4;
	font-weight: 400;
}
.font-color {
	color: #fb4a3e;
	margin-left: 5px;
}
.font-red {
	color: #fb4a3e !important;
}
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 18px;
	color: #8c939d;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
}
.avatar {
	width: 80px;
	height: 80px;
	display: block;
}
</style>
<style>
.el-form-item__label {
	color: #333;
}
</style>